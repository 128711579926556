.login_bg {
    background: url("./priceteka-bg.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
}

.logo_area {
    text-align: center;
    padding-top: 150px;
    padding-bottom: 50px;
    width: 200px;
    margin: auto;
}

.login_box{
    width: 400px;
}