
.custom_tooltip {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    width: 100px;

    .day {
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 400;
    }

    .label {
        //padding-bottom: 15px;
        font-weight: bold;
        font-size: 14px;
    }
    .clicks{
        font-size: 20px;
        font-weight: bold;
        span{
            font-size: 12px;
            font-weight: 400;
        }
    }
    .price{
        font-weight: bold;
        font-size: 14px;
        span{
            font-size: 12px;
            font-weight: 400;
        }
    }
}