$primary: #822165 !default;
$secondary: #1a86cf !default;
$success: #1a9a55 !default;
$info: #1a86cf !default;
$warning: #f5c033 !default;
$danger: #d1370a !default;
$bg-gray: #FAFAFA !default;


.bg-white {
    background-color: white;
}
.w-60 {
    width: 60% !important;
}
.w-40 {
    width: 40% !important;
}
.currency {
    font-size: 80%;
    //vertical-align: text-top;
    padding-left: 2px;
}

.primary-color {
    color: $primary;
}

.rs-btn-primary {
    background-color: $primary;
}

.rs-btn-primary:focus,
.rs-btn-primary:hover {

    background-color: rgba($primary, 0.9);
}

.rs-btn-icon-with-text.rs-btn-primary>.rs-icon {
    background-color: $primary;
}

.rs-btn-icon-with-text.rs-btn-primary:focus>.rs-icon,
.rs-btn-icon-with-text.rs-btn-primary:hover>.rs-icon {
    background-color: rgba($primary, 0.9);
}

.rs-btn-link {
    color: $primary;
}

.rs-btn-link:focus,
.rs-btn-link:hover {
    color: $primary;
}

small {
    font-size: 70%;
}



.rs-sidebar {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.12);
    height: 100vh;
    z-index: 1;
    background-color: $primary;
    position: fixed;
}

.rs-sidenav-header {
    background: $primary;
    color: white;
    padding: 18px;
    font-size: 16px;
    height: 56px;
    background: rgb(130, 33, 101);
    color: rgb(255, 255, 255);
    white-space: nowrap;
    overflow: hidden;

    img {
        width: 140px;
    }
}

.page-wrap {
    background-color: $bg-gray;
    //padding: 10px;
    border-radius: 10px;
    padding-left: 220px;
    margin-left: auto;

    .rs-content {
        background-color: white;
        padding: 20px;
    }
}

.shop-name {
    position: absolute;
    top: 0px;
    right: 10px
}

.rs-sidenav-subtle .rs-dropdown-item,
.rs-sidenav-subtle .rs-dropdown-toggle,
.rs-sidenav-subtle .rs-sidenav-item,
.rs-sidenav-subtle .rs-sidenav-toggle-button {
    color: #f2f2f2;
}

.rs-sidenav-subtle .rs-dropdown-item.rs-dropdown-item-active,
.rs-sidenav-subtle .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
.rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active {
    color: #f2f2f2;
    background-color: rgba(#68144f, 0.9);
}

.rs-sidenav-subtle .rs-dropdown-item:focus-visible,
.rs-sidenav-subtle .rs-dropdown-item:hover,
.rs-sidenav-subtle .rs-dropdown-toggle:focus-visible,
.rs-sidenav-subtle .rs-dropdown-toggle:hover,
.rs-sidenav-subtle .rs-sidenav-item:focus-visible,
.rs-sidenav-subtle .rs-sidenav-item:hover,
.rs-sidenav-subtle .rs-sidenav-toggle-button:focus-visible,
.rs-sidenav-subtle .rs-sidenav-toggle-button:hover {
    background-color: rgba(#68144f, 0.9);
    color: #f2f2f2;
}

.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
.rs-sidenav-subtle .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    background-color: inherit;
}


.wrap-panel {
    .rs-panel-header {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.big-counter {
    .rs-panel-header {
        font-size: 12px !important;
        font-weight: bold;
        text-align: center;
        padding: 15px 15px 5px;
    }

    .rs-panel-body {
        font-size: 30px;
        font-weight: bold;
    }

    .currency {
        //font-size: 50%;
    }
}

.big-counter-total {
    background-color: $primary;
    color:white;
    .rs-panel-header {
        font-size: 16px !important;
        font-weight: bold;
        text-align: center;
        padding: 15px 15px 5px;
        color:white;
    }

    .rs-panel-body {
        font-size: 45px;
        font-weight: bold;
    }

    .currency {
        //font-size: 50%;
    }
}

.w-15 {
    width: 15%;
}

.rs-picker-toggle-textbox {
    color: black;

    ::placeholder {
        color: black;
    }

    ::-webkit-input-placeholder {
        color: black;
    }
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: black;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: $primary;
}

.rs-calendar-table-cell-in-range:before {
    background-color: rgba($primary, 0.1);
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: $primary;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgba($primary, 0.9);
    color: white;
}

.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
    background-color: rgba($primary, 0.5)
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    border-color: black;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    color: $success;
    border-color: $success;
}

.rs-steps-item-status-finish .rs-steps-item-content {
    color: $success;
}

.rs-tag-primary {
    color: white;
    background-color: $primary;
}

.rs-checkbox-wrapper:before {
    color: $primary;
    border: 1px solid $primary;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: $primary;
    border-color: $primary;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    background-color: $primary;
    border-color: $primary;
}

.rs-picker-cascader-search-match {
    color: $primary;
}

.rs-radio-tile-mark {
    background: $primary;
}

.rs-radio-tile-icon {
    padding-right: 10px;
}


.rs-radio-tile-checked,
.rs-radio-tile:hover:not(.rs-radio-tile-disabled) {
    border: 2px solid $primary;
}

.rs-radio-tile-checked.rs-radio-tile-disabled {
    border-color: $primary;
}

.rs-picker-value-count {
    background-color: $primary;
}

.rs-radio-tile-checked.rs-radio-tile-disabled .rs-radio-tile-mark {
    background-color: $primary;
}



// .rs-picker-daterange-predefined .rs-btn{
//     //color:#d1370a
// }

.thumb-autocomplete {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
}

.thumb-tag {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
}

.small-thumb {
    width: 50px;
    display: inline-block;
    padding-right: 10px;
    vertical-align: middle;
    position: absolute;
    top: 3px;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.title-with-thumb {
    padding-left: 50px;
}


.rs-form-group {
    display: flex;
}

.rs-form-help-text-tooltip {
    margin-top: 0;
    ;
}

.rs-form-control-label {
    font-weight: bold;
}

.rs-drawer-backdrop {
    z-index: 9998;
}

.rs-drawer-wrapper {
    z-index: 9999;
}

$primary: #822165 !default;
$secondary: #1a86cf !default;
$success: #1a9a55 !default;
$info: #1a86cf !default;
$warning: #f5c033 !default;
$danger: #d1370a !default;
$bg-gray: #FAFAFA !default;

.status-badge {
    line-height: 1;
    padding: 2px 6px 3px;
    font-size: 10px;

    &.active,
    &.started,
    &.ok {
        background-color: $success;
    }

    &.draft {
        background-color: gray;
    }

    &.disabled,
    &.canceled,
    &.declined,
    &.failed,
    &.error {
        background-color: $danger;
    }

    &.pending,
    &.paused,
    &.warning,
    &.daily_budg {
        background-color: $warning;
    }

    &.rescheduled {
        background-color: $info;
    }

    &.completed {
        background-color: #3b3b3b;
    }
    &.archived{
        background-color: $info;
    }
    

    &.new {
        background-color: $success;
    }

    &.mapped {
        background-color: $danger;
    }

    &.ended {
        background-color: gray;
    }
}


.all_campaigns {
    .rs-table-cell-header {
        font-weight: bold;

        // background-color: #822165;
    }

    .rs-table-cell-header {
        // background: $primary;
        //  color: white;
    }
}

.label-pickers-menu {
    color: white;
}

.rs-toast-container {
    z-index: 9990;
}
.rs-table-cell-content{
    font-size: 12px;
}