.steps_custom {
    width: 900px;
    display: inline-table;
    vertical-align: top;
    position: fixed;
    left: 220px;
    top: 0px;
    z-index: 1;
    background: white;
    z-index: 9977;
    border-bottom: 1px solid rgb(209, 209, 209);

}

.impact {
    position: fixed;
    left: 800px;
    top: 85px;
    width: 315px;
}

.confirm_buttons {
    padding-bottom: 250px;
    padding-top: 50px;
}

